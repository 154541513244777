
/* Reset */
html,
body,
div {
  border: 0;
  margin: 0;
  padding: 0;
  color: #2a2a2a;
}


/* Box-sizing border-box */
* {
  box-sizing: border-box;
}

/* Set up a default font and some padding to provide breathing room */
body {
  font-family: 'Open Sans', sans-serif;
  input, select {
    font-family: 'Open Sans', sans-serif;
  }
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .app-loading {
    margin-left: 10px;
  }
}

p {
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 20px;
  margin-bottom: 1em;
  margin-top: 1em;
}

ul {
  margin: 10px 0 0 0;
  padding: 0 0 0 20px;
}

li {
  font-weight: 400;
  margin-top: 4px;
}

input {
  border: 1px solid #106cc8;
  font-size: 14px;
  height: 40px;
  outline: none;
  padding: 8px;
}

button {
  background-color: transparent;
  color: #005d83;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
  border-radius: 3px;
}

.layout-row {
  display:flex;
  flex-direction: row;
}

.layout-column {
  display:flex;
  flex-direction: column;
}

.flex-none {
  flex: none;
}

.flex-separator {
  flex-grow: 1;
}

.md-padding {
  padding: 16px;
}

.clear {
  overflow: auto;
}

panel-bar.even > mat-accordion > mat-expansion-panel {
  background-color: white;
}
panel-bar.odd > mat-accordion > mat-expansion-panel {
  background-color: #fafafa;
}

