// == font overrides
$font-family-oswald: 'Oswald', sans-serif;
$font-family-open-sans: 'OpenSans', sans-serif;

//#region Colors
//#region Color - Base
//#region Color - Neutral
$vsp-black-7p: #efefef;
$vsp-black: #000000;
$vsp-gray: #595959;
$vsp-light-gray: #d6d6d6;
$vsp-medium-gray: #8c8c8c;
//#endregion

//#region Color - Core
$vsp-blue-black: #0b2335;
$vsp-blue-black-v1: #003046;
$vsp-blue: #3a60ff;
$vsp-dark-blue: #241ed6;
$vsp-light-blue: #b4dbf7;
$vsp-white: #ffffff;
//#endregion

//#region Color - Secondary
$vsp-dark-green: #035b44;
$vsp-dark-teal: #004761;
$vsp-dark-violet: #640e87;
$vsp-light-green: #b9f4d3;
$vsp-light-teal: #a6efef;
$vsp-light-violet: #d8c9ef;
$vsp-medium-green: #31cc75;
$vsp-medium-teal: #00bfbf;
$vsp-medium-violet: #9b5bcc;
$vsp-orange-yellow: #ffc709;
$vsp-orange: #ffa500;
$vsp-red: #ed423a;
//#endregion

//#region Color - Limited
$vsp-premier-accent: #e44326;
$vsp-premier-orange: #f47b20;
//#endregion

//#region Color - Angular Assets
$vsp-success-green: #298600;
$vsp-error-red: #d50001;
$vsp-alert-amber: #bd5b01;
$vsp-priced-orange: #e5620b;
$vsp-data-table-alter-row-gray: #f6f7f8;
$vsp-form-element-hover-blue: #f6f6ff;
$vsp-disabled-text-field-gray: #fafafa;
//#endregion
//#endregion


/** START - FONT SIZE VARS **/
// Font size should be kept in pixels for resizing of spacing (via zoom), which is kept in rems
$display1FontSize: 30px;
$display2FontSize: $display1FontSize;
$display3FontSize: 40px;
$display4FontSize: $display3FontSize;
$display5FontSize: 50px;
$display6FontSize: $display5FontSize;
$heading1FontSize: 24px;
$heading2FontSize: 20px;
$heading3FontSize: 18px;
$subheadFontSize: 16px;
$bodyFontSize: 14px;
$captionFontSize: 12px;
$subcaptionFontSize: 10px;
/** START - FONT SIZE VARS **/


/** START - SIZE VARS **/
$appWidth: 1170px;
$appWidthRem: 117rem;

$extraSmallSpacer: 1rem; // 10px
$smallSpacer: 2rem; // 20px
$mediumSpacer: 3rem; // 30px
$largeSpacer: 4rem; // 40px
$extraLargeSpacer: 5rem; // 50px

$iconSize: 2.4rem; // 24px
$smallIconSize: 1.8rem; // 18px

$tabHeight: 4.4rem; // 44px

$gridSpacing: 2.4rem; // 24px
$gridRowHeight: 4.8rem; // 48px

$borderSize: 1px;
/** END - SIZE VARS **/

/** START - COLOR VARS **/
$error-color: #D50001;
$alert-color: #BD5B01;
$white: #FFF;
$menu-item-hover-background-color: #EBF5F9;
$border-color: #E0E0E0;
$secondary-border-color: #D4D3D4;
$gridAlternateBackgroundColor: #F2F2F2;
$background: #FAFAFA;
$light-background: $white;
$dark-mode-background: #303030;
$dark-mode-light-background: #3e3e3e;
$disabled-color: #58595B;
$disabled-background: #CECECE;
$positive-indicator: #298600;
$dashboard-component: #83388E;
$light-blue: #0069B5;
$blue-lagoon: #005D83;
$primary-color: #003046;

$border: $borderSize solid $border-color;

$actionableColor: $blue-lagoon;
$actionableHoverColor: rgba($actionableColor, 0.15);

/** END - COLOR VARS **/


/** START - ANIMATION VARS **/
$animationDuration: 250ms;
$fullAnimation: 250ms ease-in-out;
/** END - ANIMATION VARS **/

/** START - FONT VARS **/
$font-family-oswald: 'Oswald', sans-serif;
$font-family-open-sans: 'OpenSans', sans-serif;
/** END - FONT VARS **/

// Base Paths
$fonts-base-path: '~src/assets/fonts/';
$icons-base-path: '~src/assets/icons/';
$icons-dark-mode-path: '~src/assets/icons/dark-mode/';
$images-base-path: '~src/assets/images/';

// == Units
$unit-1: 1px;
$unit-2: 2px;
$unit-3: 3px;
$unit-4: 4px;
$unit-5: 5px;
$unit-6: 6px;
$unit-8: 8px;
$unit-10: 10px;
$unit-12: 12px;
$unit-14: 14px;
$unit-15: 15px;
$unit-16: 16px;
$unit-17: 17px;
$unit-18: 18px;
$unit-20: 20px;
$unit-24: 24px;
$unit-25: 25px;
$unit-30: 30px;
$unit-35: 35px;
$unit-36: 36px;
$unit-40: 40px;
$unit-48: 48px;
$unit-50: 50px;
$unit-60: 60px;
$unit-70: 70px;
$unit-75: 75px;
$unit-80: 80px;
$unit-90: 90px;
$unit-100: 100px;
$unit-120: 120px;
$unit-200: 200px;
$unit-240: 240px;
$unit-288: 288px;
$unit-300: 300px;