.flex {
  flex: 1;
}
@for $i from 0 through 20 {
  $value : #{$i * 5 + '%'};

  .flex-#{$i * 5} {
    flex: 1 1 #{$value};
    max-width: #{$value};
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-#{$i * 5} {
    flex: 1 1 #{$value};
    max-width: #{$value};
    max-height: 100%;
    box-sizing: border-box;

    // Bug workaround for http://crbug.com/546034 - flex issues on Chrome 48
    @if $i == 0 {  min-width: 0;  }
  }

  .layout-column > .flex-#{$i * 5} {
    flex: 1 1 #{$value};
    max-width: 100%;
    max-height: #{$value};
    box-sizing: border-box;
  }

  .layout-row {
    > .flex-33   , > .flex-33     {  flex: 1 1 33.33%;  max-width: 33.33%;  max-height: 100%; box-sizing: border-box; }
    > .flex-66   , > .flex-66     {  flex: 1 1 66.66%;  max-width: 66.66%;  max-height: 100%; box-sizing: border-box; }
  }

  .layout-column {
    > .flex-33   , > .flex-33     {  flex: 1 1 33.33%;  max-width: 100%;  max-height: 33.33%; box-sizing: border-box; }
    > .flex-66   , > .flex-66     {  flex: 1 1 66.66%;  max-width: 100%;  max-height: 66.66%; box-sizing: border-box; }
  }

  .layout-row > .flex-#{$i * 5} {
    flex: 1 1 #{$value};
    max-width: #{$value};
    max-height: 100%;
    box-sizing: border-box;

    // Bug workaround for http://crbug.com/546034 - flex issues on Chrome 48
    @if $i == 0 {  min-width: 0;  }
  }

  .layout-column > .flex-#{$i * 5} {
    flex: 1 1 #{$value};
    max-width: 100%;
    max-height: #{$value};
    box-sizing: border-box;

    // Bug workaround for http://crbug.com/546034 - flex issues on Chrome 48
    @if $i == 0 {  min-height: 0;  }
  }

}

/* IE10-IE11 column-flex bug fix (set proper default value) */
.layout-column > .flex {
  -ms-flex-basis: auto;
  flex-basis: auto;
}
