@import 'theme';
@import 'vsp.mixins';
@import 'vsp.print';
@import 'variables';

//** START - SPINNER **//
.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: $actionableColor;
}
//** END - SPINNER **//

//** START - GENERIC **//
.mat-app-background {
    display: block;
    font-size: $bodyFontSize;
    margin: 0 auto;
    padding: 0 4vw;
}

.mat-ripple-element {
    background-color: $actionableHoverColor;
}
//** END - GENERIC **//


//** START - AUTO COMPLETE **//
.mat-option {
    color: $primary-color !important;
    font-family: $font-family-open-sans !important;
    font-size: $bodyFontSize;
    font-weight: normal !important;

    &:hover:not(.mat-option-disabled),
    &:focus:not(.mat-option-disabled),
    &.mat-active {
        background: $background !important;
        color: $light-blue !important;
    }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $actionableColor;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background-color: $background !important;
}

.mat-select-content,
.mat-select-panel-done-animating {
    background-color: $background !important;
}

.mat-select-panel {
    margin-top: 35px;
    margin-bottom: 40px;
}

mat-option.no-search-results-option {
    height: auto;

    a {
        display: block;

        span {
            display: block;
        }

        .message {
            color: $primary-color;
        }

        .link {
            text-align: right;
        }
    }
}
//** END - AUTO COMPLETE **//


//** START - SIDE NAV **//
.mat-drawer-container {
    background-color: $light-background;

    .mat-drawer-backdrop.mat-drawer-shown {
        background-color: transparent;
    }

    .mat-sidenav-content {
        display: flex;

        button.mat-button {
            display: inline-flex;
        }
    }
}
//** END - SIDE NAV **//


//** START - CHECKBOX **//
mat-checkbox.mat-checkbox {
    color: $primary-color;
    display: block;
    font-size: $bodyFontSize;

    .mat-checkbox-inner-container {
        margin-right: $extraSmallSpacer;
    }

    .mat-checkbox-frame {
        border-color: $primary-color;
    }

    &:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
        background-color: $actionableHoverColor;
    }

    &.mat-checkbox-checked .mat-checkbox-background {
        background-color: $actionableColor;
    }

    &.mat-checkbox-disabled,
    &.mat-checkbox-disabled:hover {
        color: $disabled-color;
        cursor: not-allowed;

        .mat-checkbox-frame {
            border-color: $disabled-color;
        }

        .mat-checkbox-ripple {
            background-color: transparent;
        }

        &.mat-checkbox-checked .mat-checkbox-background {
            background-color: $disabled-color;
        }
    }

    &:not(.checkbox-list-item):hover .mat-checkbox-ripple {
        background-color: $actionableHoverColor;
        border-radius: $extraLargeSpacer;
    }
}

.mat-option.checkbox-list-option {
    .mat-checkbox:hover .mat-checkbox-ripple {
        background-color: initial;
        border-radius: initial;
    }
}
//** END - CHECKBOX **//


//** START - SELECT **//
mat-select.select-policy {
    padding-left: 5px;
    width: 35%;
}
//** END - SELECT **//


//** START - RADIO **//
mat-radio-button.mat-radio-button {
    color: $primary-color;
    font-size: $bodyFontSize;

    .mat-radio-label-content {
        padding-left: $extraSmallSpacer;
    }

    .mat-radio-outer-circle {
        border-color: $primary-color;
    }

    &:not(.mat-radio-disabled).mat-accent .mat-radio-ripple .mat-ripple-element {
        background-color: $actionableHoverColor;
    }

    &:hover .mat-radio-ripple {
        background-color: $actionableHoverColor;
        border-radius: $extraLargeSpacer;
    }

    &.mat-radio-checked.mat-accent .mat-radio-outer-circle,
    &.mat-radio-checked.mat-accent .mat-radio-inner-circle {
        border-color: $actionableColor;
    }

    &.mat-radio-checked.mat-accent .mat-radio-inner-circle {
        background-color: $actionableColor;
    }

    &.mat-radio-disabled.mat-accent,
    &.mat-radio-disabled.mat-accent:hover {
        color: $disabled-color;

        .mat-radio-ripple {
            background-color: transparent;
        }

        .mat-radio-outer-circle {
            border-color: $disabled-color;
        }

        &.mat-radio-checked .mat-radio-inner-circle {
            background-color: $disabled-color;
        }
    }
}
//** END - RADIO **//


//** START - MODAL DIALOG **//
mat-dialog-container.mat-dialog-container {
    font-size: $bodyFontSize;
    overflow: none;
    padding: 0

    h2 {
        margin-bottom: $mediumSpacer;
    }

    .mat-dialog-content {
        margin: 0;
        overflow: initial;
        padding: 0 0 1rem 0;
    }

    .button-container {
        margin-top: $largeSpacer;

        a.call-to-action {
            margin-left: $mediumSpacer;
        }
    }
}

//** END - MODAL DIALOG **//


//** START - TABLE **//
.mat-table {
    background-color: transparent;
    border-bottom: $border;
    border-top: $border;

    .mat-header-row,
    .mat-row,
    .primary-table-row {
        background: $light-background;
        min-height: $gridRowHeight;
        overflow: hidden;
    }

    .mat-row,
    .primary-table-row {
        border-bottom: $border;
        position: relative;

        .table-icon-cell {
            overflow: visible;
            transition: min-height $fullAnimation;

            .table-icon-link {
                display: inline-block;
                height: $gridRowHeight;
                line-height: initial;
                margin-left: $smallSpacer;
                transition: transform $fullAnimation;
                z-index: 1;

                &.default-control svg {
                    fill: $primary-color;
                }

                &.expanded {
                    transform: rotateX(180deg);
                }

                svg {
                    height: $gridRowHeight;
                }
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }

    tr.mat-row:hover {
        background-color: $menu-item-hover-background-color;
    }

    .primary-table-row:nth-child(even),
    .mat-row:nth-child(even) {
        background-color: $gridAlternateBackgroundColor;
    }

    .primary-table-row:nth-child(odd),
    .mat-row:nth-child(odd) {
        background-color: $white;
    }

    .mat-header-row {
        .mat-header-cell {
            color: $primary-color;
            font-size: $captionFontSize;
        }

        .icon {
            display: inline-block;
            height: $smallIconSize;
            margin-right: $extraSmallSpacer;
            width: $smallIconSize;

            svg {
                height: $smallIconSize;
                width: $smallIconSize;
            }
        }
    }

    .mat-cell {
        font-size: $bodyFontSize;
        line-height: $gridRowHeight;
        min-height: $gridRowHeight;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .table-icon-header {
        text-align: right;
    }

    .add-on-table-row {
        line-height: $gridRowHeight;
        text-align: center;
    }
}

mat-paginator.mat-paginator {
    background-color: transparent;
    color: $primary-color;
    font-size: 12px;

    .mat-paginator-increment,
    .mat-paginator-decrement {
        border-right: 2px solid $primary-color;
        border-top: 2px solid $primary-color;
        height: 7px;
        width: 7px;
    }
}
//** END - TABLE **//


//** START - SPINNER **//
.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: $actionableColor;
}
//** END - SPINNER **//


//** START - SNACKBAR **//
snack-bar-container.mat-snack-bar-container {
    background-color: $blue-lagoon;
    color: $white;
}

snack-bar-container.error-message {
    background-color: $error-color;

    .mat-simple-snackbar-action button {
        background-color: $error-color;
        font-size: $subheadFontSize;
    }
}
//** END - SNACKBAR **//


//** START - TABS **//
.mat-tab-group {
    .mat-tab-body-wrapper {
        background-color: $light-background;
        border: $border;
        border-bottom-style: none;
    }

    .mat-tab-header {
        border-bottom: none;
        margin-top: $mediumSpacer;
        transform: translate(0, 1px);
        z-index: 1;

        .mat-tab-label {
            background-color: $light-background;
            border: $border;
            color: $actionableColor;
            margin-right: 10px;
            opacity: 1;
        }

        .mat-tab-label-active {
            background-color: $light-background;
            border-bottom: none;
            font-weight: bold;
        }
    }

    .mat-tab-list mat-ink-bar.mat-ink-bar {
        background-color: transparent;
    }
}
//** END - TABS **//


//** START - DIVIDER **//
mat-divider.mat-divider {
    margin-top: 20px;
    padding: 10px 0;
}
//** END - DIVIDER **//

.cdk-overlay-dark-backdrop {
    background: rgba(0,0,0,.5) !important;
}

.mat-dialog-container {
    padding: 0 0 30px 0 !important;
}

.mat-slide-toggle:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: $disabled-color!important;
}

.mat-slide-toggle:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: $background !important;
}


.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: $light-blue !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: $background !important;

    &:hover {
        background-color: $light-blue !important;
    }
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-ripple-element {
    background-color: $light-blue !important;
}
