/** START - PALETTES **/
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dark-palette: (
  50 : #001320,
  100 : #001e2f,
  200 : #002437,
  300 : #00557b,
  400 : #002b3f,
  500 : #264f62,
  600 : #4d6e7e,
  700 : #8098a3,
  800 : #b3c1c8,
  900 : #e0e6e9,
  A100 : #0681ff,
  A200 : #1f8eff,
  A400 : #52a7ff,
  A700 : #85c1ff,
  contrast: (50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #000000,
    A700 : #000000,
  )
);
$md-globaldarkblue: (50: #e0e6e9,
  100: #b3c1c8,
  200: #8098a3,
  300: #4d6e7e,
  400: #264f62,
  500: #003046,
  600: #002b3f,
  700: #002437,
  800: #001e2f,
  900: #001320,
  A100: #5daaff,
  A200: #2a8fff,
  A400: #0074f6,
  A700: #0068dc,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$md-globalpurple: (50: #f1e7f2,
  100: #dcc4e0,
  200: #c59dcb,
  300: #ae76b6,
  400: #9c58a6,
  500: #8b3b96,
  600: #83358e,
  700: #782d83,
  800: #6e2679,
  900: #5b1968,
  A100: #efa2ff,
  A200: #e66fff,
  A400: #dd3cff,
  A700: #d923ff,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);
$md-globallightblue: (50: #e0f7fd,
  100: #b3eafb,
  200: #80dcf8,
  300: #4dcef5,
  400: #26c4f3,
  500: #00b9f1,
  600: #00b2ef,
  700: #00aaed,
  800: #00a2eb,
  900: #0093e7,
  A100: #ffffff,
  A200: #daf0ff,
  A400: #a7dbff,
  A700: #8ed1ff,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-vspgreen: (50: #f3f9e7,
  100: #e2f0c4,
  200: #cfe69d,
  300: #bcdb76,
  400: #add458,
  500: #9fcc3b,
  600: #97c735,
  700: #8dc02d,
  800: #83b926,
  900: #72ad19,
  A100: #f3ffe2,
  A200: #ddffaf,
  A400: #c7ff7c,
  A700: #bcff63,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-omnichannelred: (50: #f9e4e5,
  100: #f0bcbf,
  200: #e79095,
  300: #dd636a,
  400: #d5414a,
  500: #ce202a,
  600: #c91c25,
  700: #c2181f,
  800: #bc1319,
  900: #b00b0f,
  A100: #ffdddd,
  A200: #ffaaab,
  A400: #ff7779,
  A700: #ff5d5f,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
/** END - PALETTES **/