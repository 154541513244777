@import 'normalize';
@import 'vsp.mixins';
@import 'vsp.print';
@import 'variables';

/** START - VSP FONT DECLARATIONS **/
/**
 *  IMPORTANT: Need to add fonts to the angular.json assets config with an input of
 *  "node_modules/@vsp/angular/core/fonts" and an output of "assets/fonts"
 *  see here for details: https://github.com/angular/angular-cli/issues/5213
 **/

//noinspection CssUnknownTarget
@font-face {
    font-family: OpenSans;
    src: url('#{$fonts-base-path}OpenSans-Regular.woff2') format('woff2');
    src: url('#{$fonts-base-path}OpenSans-Regular.woff') format('woff');
}

//noinspection CssUnknownTarget
@font-face {
    font-family: OpenSans;
    font-weight: bold;
    src: url('#{$fonts-base-path}OpenSans-SemiBold.woff2') format('woff2');
    src: url('#{$fonts-base-path}OpenSans-SemiBold.woff') format('woff');
}

// Oswald Font
@font-face {
    font-family: Oswald;
    src: url('#{$fonts-base-path}Oswald-Regular.ttf') format('ttf');
}

@font-face {
    font-family: Oswald;
    font-weight: medium;
    src: url('#{$fonts-base-path}Oswald-Medium.ttf') format('ttf');
}

@font-face {
    font-family: Oswald;
    font-weight: bold;
    src: url('#{$fonts-base-path}Oswald-SemiBold.ttf') format('ttf');
}

/** END - VSP FONT DECLARATIONS **/

html {
    font-family: OpenSans, 'Segoe UI', sans-serif;
    font-size: 62.5%;
}

html,
body {
    height: 100%;
}

.display-1 {
    font-size: $display1FontSize;
}

.display-2 {
    font-size: $display2FontSize;
}

.display-3 {
    font-size: $display3FontSize;
}

.display-4 {
    font-size: $display4FontSize;
}

.display-5 {
    font-size: $display5FontSize;
}

.display-6 {
    font-size: $display6FontSize;
}

.display-2,
.display-4,
.display-6 {
    font-weight: bold;
}

h1,
h2,
h3,
h4,
h5 {
    display: block;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

h1 {
    font-size: $heading1FontSize;
}

h2 {
    font-size: $heading2FontSize;
}

h3 {
    font-size: $heading3FontSize;
    margin-bottom: $smallSpacer;
}

h4,
h5 {
    font-size: $subheadFontSize;
}

h5 {
    font-weight: normal;
}

p {
    margin: 0 0 $smallSpacer 0;
    padding: 0;
}

.page-content {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    min-height: 73.02vh;
    padding-top: $mediumSpacer;

    .content-container {
        flex-basis: 100%;
    }

    .content {
        background-color: $light-background;
        border: $border;
        margin-top: $mediumSpacer;
        padding: $mediumSpacer $smallSpacer;
    }

}

.informational-text,
.caption {
    font-size: $captionFontSize;
}

.sub-caption {
    font-size: $subcaptionFontSize;
}

.field {
    font-family: sans-serif;
    font-weight: bold;
    margin-left: 2px;
}

.icon {
    height: $iconSize;
    width: $iconSize;
}

b,
em {
    font-style: normal;
    font-weight: bold;
}

a {
    color: $light-blue;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    svg {
        fill: $actionableColor;
    }

}

a.active {
    color: $blue-lagoon;
    font-weight: bold;
}

a.focus {
    color: $blue-lagoon;
}
a.call-to-action,
.call-to-action {
    font-weight: bold;
    text-transform: uppercase;

    &:focus {
        color: $primary-color;
        outline: none;
    }
}

a:hover,
a:link,
a:visited {
    cursor: pointer;
}

a:hover {
    text-decoration: underline;
}

.disabled,
a.call-to-action.disabled,
.call-to-action.disabled {
    color: $disabled-color;
    cursor: default;

    &:hover {
        cursor: default;
    }

    &:focus {
        color: $disabled-color;
    }

    a,
    a:hover,
    a:focus {
        color: $disabled-color;
        cursor: default;
    }

    svg {
        fill: $disabled-color;
    }

}

.align-right {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
}

.align-left {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.full-width {
    width: 100%;
}

.center {
    left: 50%;
    position: absolute;
    top: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.286);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 200;
}

.hidden {
    // Purposeful use of !important to enforce this CSS class
    display: none !important;
}

.flex {
    display: flex;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: $extraSmallSpacer;
}

.close-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: -$extraSmallSpacer;
}

.col {
    flex: 1;
    margin-right: 20px;
}

.col:last-child {
    margin-right: 0;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.button-loader {
    background-color: $primary-color;
    opacity: 1 !important;
    position: relative;
}

.button-loader-img {
    animation: spin 1s linear infinite;
    background-image: url('#{$images-base-path}vsp_loader_white.svg');
    background-repeat: no-repeat;
    height: 30px;
    left: 50%;
    margin: -15px 0 0 -15px;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 30px;
}

.button-loader-original-contents {
    visibility: hidden;
}

.vsp-logo {
    background-image: url('#{$images-base-path}VSP_Global_4c.png');
    background-size: contain;
}

.timestamp {
    font-size: $captionFontSize;
    margin-top: $mediumSpacer;
}

.breadcrumb {
    margin-bottom: -$extraSmallSpacer;
    margin-top: $smallSpacer;
    padding-left: $smallSpacer;

    .breadcrumb-item {
        color: $blue-lagoon;
        text-decoration: none;
        vertical-align: middle;
    }
}

.add-button {
    border: 1px solid;
    margin-top: 60px;
}

.dark-theme {

    html,
    body {
        background-color: $dark-mode-background;
    }

    .vsp-logo {
        background-image: url('#{$images-base-path}vision-care-white.png');
    }

    app-consumer .consumer {
        border-color: $disabled-color;
    }

    .page-content .content {
        background-color: $white;
        border-color: $disabled-color;
    }
}

.form-content {
    margin-top: 30px;
}
